import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import Breadcrumbs from "components/Breadcrumbs"
import PageHeader from "components/PageHeader"

import { Content } from "page_components/portfolio_listing"

const Portfolio = ({ data }) => {
  const title = "Portfolio"
  const allPortfolio = data.allWpPortfolio.nodes

  return (
    <Layout seo={{ title: title }}>
      <Breadcrumbs title={title} />
      <PageHeader title={title} />
      <Content allPortfolio={allPortfolio} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpPortfolio(sort: { fields: date, order: DESC }) {
      nodes {
        id
        title
        uri
        slug
        featuredImage {
          node {
            localFile {
              publicURL
            }
          }
        }
        acfPortfolio {
          city
          address
          area
          shortDescription
        }
      }
    }
  }
`

export default Portfolio
